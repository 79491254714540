<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="next"
        @restart="() => { stage = stage == 3 ? 2 : 0 }"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="courier-pricing"
        title="Price Tiers">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="courier-price-tiers"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.priceTierName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <BT-Btn small text icon="mdi-pencil" @click="editTier(item)" />
                                    <BT-Btn small text icon="mdi-delete" @click="removeTier(item)" />
                                </v-row>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add a price tier?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Tier Name"
                        v-model="newItem.priceTierName"
                        isEditing />

                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <v-subheader>Priced Items</v-subheader>

                    <div v-for="(item, index) in newItem.priceTierItems" :key="index">
                        <Price-Tier-Item-Blade
                            isEditing
                            :item="item"
                            showDelete
                            @delete="newItem.priceTierItems.splice(index, 1)">
                            <template v-slot="{ item }">
                                <span v-if="item.itemType === 'Weight'">Kilogram</span>
                                <span v-else-if="item.itemType === 'Volume'">Litre</span>
                                <span v-else-if="item.itemType === 'Distance'">Kilometre</span>
                                <BT-Entity
                                    v-else-if="item.itemID != null && item.itemType === 'Zone'"
                                    navigation="zones"
                                    itemText="zoneName"
                                    :itemValue="item.itemID" />
                                <BT-Entity
                                    v-else-if="item.itemID != null && item.itemType === 'Measurement'"
                                    navigation="measurements"
                                    itemText="measurementName"
                                    :itemValue="item.itemID">
                                </BT-Entity>
                            </template>
                        </Price-Tier-Item-Blade>
                        <v-divider class="my-1" />
                    </div>

                    <v-row no-gutters class="mt-4">
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Add Price Item"
                            @click="addPricedItem" />

                        <v-spacer />
                        
                        <BT-Btn
                            buttonClass="primary mx-1"
                            inline
                            label="Done"
                            @click="saveTier" />
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <BT-List-Item-Check
                        v-if="!showMeasurements"
                        @true="typeZone"
                        @false="cancelType"
                        class="my-3"
                        :falseValue="null"
                        icon="mdi-texture-box"
                        subtitle="Price for deliveries within a certain geographical area."
                        title="Zone"
                        trueValue="Zone"
                        v-model="newPricedItem.itemType" />
                    
                    <BT-List-Item-Check
                        v-if="!showMeasurements && !showZones"
                        @true="typeWeight"
                        @false="cancelType"
                        class="my-3"
                        :falseValue="null"
                        icon="mdi-weight"
                        subtitle="Price per kilogram of consignment."
                        title="Weight (Kg)"
                        trueValue="Weight"
                        v-model="newPricedItem.itemType" />

                    <BT-List-Item-Check
                        v-if="!showMeasurements && !showZones"
                        @true="typeVolume"
                        @false="cancelType"
                        class="my-3"
                        :falseValue="null"
                        icon="mdi-tape-measure"
                        subtitle="Price per unit of volume of consignment."
                        title="Volume (Ltr)"
                        trueValue="Volume"
                        v-model="newPricedItem.itemType" />
                    
                    <BT-List-Item-Check
                        v-if="!showZones"
                        @true="typePackage"
                        @false="cancelType"
                        class="my-3"
                        :falseValue="null"
                        icon="mdi-cube"
                        subtitle="Price per consignment package."
                        title="Package"
                        trueValue="Measurement"
                        v-model="newPricedItem.itemType" />

                    <BT-List-Item-Check
                        v-if="!showMeasurements && !showZones"
                        @true="typeDistance"
                        @false="cancelType"
                        class="my-3"
                        :falseValue="null"
                        icon="mdi-map-marker-distance"
                        subtitle="Price per km travelled."
                        title="Distance (Km)"
                        trueValue="Distance"
                        v-model="newPricedItem.itemType" />

                    <BT-Select-List
                        v-if="showMeasurements"
                        navigation="measurements"
                        @change="v => { if (v != null) { finishAdd(); } }"
                        itemText="measurementName"
                        itemValue="id"
                        label="Select Package"
                        :multiple="false"
                        v-model="newPricedItem.itemID" />

                    <BT-Select-List
                        v-if="showZones"
                        navigation="zones"
                        @change="v => { if (v != null) { finishAdd(); } }"
                        itemText="zoneName"
                        itemValue="id"
                        label="Select Zone"
                        :multiple="false"
                        v-model="newPricedItem.itemID" />

                </v-tab-item>

            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Price-Tier-Setup',
    components: {
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        PriceTierItemBlade: () => import('~home/price-tiers/Price-Tier-Item-Blade.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            newPricedItem: {},
            refreshList: false,
            showZones: false,
            showMeasurements: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.showZones = false;
            this.showMeasurements = false;
            this.newItem = j || { priceTierName: null, priceTierItems: [] };
            this.stage += 1;
        },
        addPricedItem(pItem) {
            this.showZones = false;
            this.showMeasurements = false;
            this.newPricedItem = pItem || {
                id: null,
                itemType: null,
                itemID: null,
                description: null,
                valueIncrements: [],
                rateFactor: 'unit',
                valuePerRateFactor: 0
            };

            if (pItem != null) {
                this.stage += 2;
            }
            else {
                this.stage += 1;
            }
        },
        // restart() {
        //     if (this.stage == 3) {
        //         this.stage = 2;
        //     }
        //     else {
        //         this.stage = 0;
        //     }
        // },
        cancelType() {
            if (this.newPricedItem != null) {
                this.newPricedItem.itemType = null;
                this.newPricedItem.itemID = null;
                this.showZones = false;
                this.showMeasurements = false;
            }
        },
        finishAdd() {
            this.newItem.priceTierItems.unshift(this.newPricedItem);
            this.stage = 2;
        },
        typeZone() {
            this.newPricedItem.itemType = 'Zone';
            this.showZones = true;
        },
        typeWeight() {
            this.newPricedItem.itemType = 'Weight';
            this.finishAdd();
        },
        typeVolume() {
            this.newPricedItem.itemType = 'Volume';
            this.finishAdd();
        },
        typePackage() {
            this.newPricedItem.itemType = 'Measurement';
            this.showMeasurements = true;
        },
        typeDistance() {
            this.newPricedItem.itemType = 'Distance';
            this.finishAdd();
        },

        async editTier(tier) {
            console.log(tier);
            if (tier != null) {
                this.newItem = await this.$BlitzIt.store.get('courier-price-tiers', tier.id);
                this.stage += 1;
            }
        },
        async removeTier(tier) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('courier-price-tiers', tier.id);
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async saveTier() {
            try {
                this.loadingMsg = 'Saving';
                this.newItem.priceTierItems = this.newItem.priceTierItems.filter(x => x.valuePerRateFactor > 0 || (this.isLengthyArray(x.valueIncrements)));
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('courier-price-tiers', this.newItem);
                }
                else {
                    res = await this.$BlitzIt.store.patch('courier-price-tiers', this.newItem);
                }

                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;
                
                this.stage = 0;
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        next(nextFunc) {
            nextFunc();
        },

    }
}
</script>